<template>
    <div class="d-flex justify-center fill-height page">
        <v-container class="d-flex justify-center align-start flex-column mx-6">
            <v-card :flat="phoneOnly" :class="{'transparent w-full': phoneOnly}" class="form-wrapper pb-8" :key="1">
                <v-form ref="codeForm" @submit.prevent="verifyEmailAction">
                    <v-card-title class="font-weight-bold justify-center mb-2 text-center">Confirm Your Email</v-card-title>
                    <v-card-subtitle class="grey--text details-text text-center mb-2 mx-4">We have sent 4-digits code to your email, enter it here to confirm your email</v-card-subtitle>
                    <digits-input color="orange darken-2" :loading="loading" :auto-focus="true" @change="updateCode" />
                    
                    <v-btn text small :disabled="sendLoading" :loading="sendLoading" class="mb-5" @click="sendCodeAgain">Send Code Again</v-btn>
                    <v-btn type="submit" block depressed large :disabled="!code || code.length !== 4 || loading" :loading="loading" color="orange darken-2" class="white--text mt-3" @click.prevent="verifyEmailAction">Confirm Email</v-btn>
                </v-form>
            </v-card>

            <div class="footer mx-auto py-3">
                <!--<v-btn text :disabled="loading" large @click="logout">
                    Logout
                    <v-icon right>mdi-arrow-left</v-icon>
                </v-btn>-->
                <!-- <v-btn text :disabled="loading" large @click="goBack">
                    <v-icon left>mdi-arrow-left</v-icon>
                    Go Back
                </v-btn> -->
            </div>
        </v-container>
    </div>
</template>

<script>

// @ is an alias to /src
import digitsInput from '../components/default/digits-input'
export default {
    name: 'Confirm',
    data(){
        return {
            loading: false,
            sendLoading: false,
            code: '',
        };
    },
    components: {
        digitsInput
    },
    computed:{
        appLoadedOnce(){
            return this.$store.state.loaded;
        },
        user(){
            return this.$store.state.userModule.user;
        },
    },
    methods: {
        verifyEmailAction(){
            // const self = this;
            let code = this.code,
                pattern = /^[0-9]{4}$/g;
            // check if reponse if parseInt for code is int, and length = 4
            if (code && pattern.test(code.toString())){
                
                this.loading = true;
                this.$store.dispatch('verifyEmailAction', {code}).then(response=>{
                    this.loading = false;
                    if (response.status === 209){
                        this.showMsg("Email already activated!", 'success');
                    }else{
                        this.showMsg("Email confirmed successfully", 'success');
                    }
                    window.setTimeout(()=>{
                        this.$router.push({name: 'home'});
                    }, 500);
                }).catch(error=>{
                    // this.$refs.codeForm.reset();
                    if (error.response && (error.response.status === 401 || error.response.status === 404 )){
                        this.showMsg("Code is invalid");
                    }else{
                        this.showMsg("Error occurred during activation, try again..");
                    }
                    
                    this.loading = false;
                })
                
            }else{
                this.showMsg("Enter code correctly! (4-digits)");
            }
        },
        sendCodeAgain(){
            this.sendLoading = true;
            this.$store.dispatch('sendCodeAgain').then(()=>{
                this.sendLoading = false;
                this.showMsg("Code sent successfully to your email", 'grey darken-3');
            }).catch(()=>{
                this.showMsg("Error occurred during sending code");
                this.sendLoading = false;
            })
        },
        updateCode(code){
            this.code = code;
        },
        showMsg(text, color = 'error', timeout = 4000){
            this.$store.dispatch('addSnackMsg', {show: true, text: text, color: color, timeout: timeout, msgBar: true});
        },
        closeMsg(){
            if (this.$store.state.globalModule.snackMsg.text){
                this.$store.dispatch('addSnackMsg', {show: false, text: '', msgBar: true});
            }
        },
        logout(){
            this.$store.dispatch('logout');
        },
        goBack(){
            this.$router.go(-1);
            // this.$router.push({path: '/'});
        },
    },
    watch: {
        code(){
            // this.closeMsg();
        }
    },
    mounted(){
        // redirect if already confirmed
        if (this.user.email_verified_at){
            this.showMsg("Account is already confirmed", 'grey darken-3');
            this.$router.push({name: 'home'});
        }
    }
}
</script>

<style lang="scss" scoped>
    .logo-wrapper{
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .logo{
            max-width: 150px;
            min-width: 120px;
            height: auto;
        }
    }
    .form-wrapper{
        width: 100%;
        text-align: center!important;
        
        @media (min-width: 960px){
            width: 400px;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>