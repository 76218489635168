<template>
    <v-row class="code-inputs">
        <v-col>
            <v-text-field @focus="resetDigit(1)" maxlength="1" type="number" min="0" max="9" name="digit1" v-model="digit1" ref="digit1Input" :disabled="loading" :color="color ? color : 'primary'" outlined hide-details></v-text-field>
        </v-col>
        <v-col>
            <v-text-field @focus="resetDigit(2)" maxlength="1" type="number" min="0" max="9" name="digit2" v-model="digit2" ref="digit2Input" :disabled="loading" :color="color ? color : 'primary'" outlined hide-details></v-text-field>
        </v-col>
        <v-col>
            <v-text-field @focus="resetDigit(3)" maxlength="1" type="number" min="0" max="9" name="digit3" v-model="digit3" ref="digit3Input" :disabled="loading" :color="color ? color : 'primary'" outlined hide-details></v-text-field>
        </v-col>
        <v-col>
            <v-text-field @focus="resetDigit(4)" maxlength="1" type="number" min="0" max="9" name="digit4" v-model="digit4" ref="digit4Input" :disabled="loading" :color="color ? color : 'primary'" outlined hide-details></v-text-field>
        </v-col>
    </v-row>
</template>

<script>

// @ is an alias to /src

export default {
    name: 'digits-input',
    props: ['loading', 'autoFocus', 'color'],
    data(){
        return {
            digit1: '',
            digit2: '',
            digit3: '',
            digit4: '',
        };
    },
    computed:{
        appLoadedOnce(){
            return this.$store.state.loaded;
        },
        code(){
            return this.digit1.toString() + this.digit2.toString() + this.digit3.toString() + this.digit4.toString();
        }
    },
    methods: {
        resetDigit(index){
            let inputName = 'digit' + index;
            this[inputName] = '';
        },
        goBack(){
            this.$router.go(-1);
            // this.$router.push({path: '/'});
        },
    },
    watch: {
        code(newCode){
            this.$emit('change', newCode);
        },
        digit1(digit){
            
            if (digit){
                
                if (digit.toString().length > 1){
                    window.setTimeout(()=>{
                        this.digit1 = digit.toString().slice(-1);
                    }, 50);
                }
                this.$refs.digit2Input.focus();

            }
        },
        digit2(digit){
            if (digit){
                if (digit.toString().length > 1){
                    window.setTimeout(()=>{
                        this.digit2 = digit.toString().slice(-1);
                    }, 50);
                }
                this.$refs.digit3Input.focus();

            }
        },
        digit3(digit){
            
            if (digit){
                window.setTimeout(()=>{
                    this.digit3 = digit.toString().slice(-1);
                }, 50);
                this.$refs.digit4Input.focus();
            }
        },
        digit4(digit){
            
            if (digit){
                window.setTimeout(()=>{
                    this.digit4 = digit.toString().slice(-1);
                }, 50);
                this.$refs.digit4Input.blur();
            }
        }
    },
    mounted(){
        if(this.autoFocus){
            setTimeout(()=>{
                this.$refs.digit1Input.focus();
            }, 500);
        }
    }
}
</script>

<style lang="scss">
.code-inputs{
    direction: ltr!important;
    .v-input{
        border-radius: 2px !important;
    }
    input{
        direction: ltr!important;
        text-align: center;
        font-weight: bold;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
}
</style>